import React, { useState, useEffect } from 'react';
import './AddArtistPage.css'
import axios from '../axiosConfig';
import StarRating from './components/StarRating';
const AddArtistPage = () => {
  // Assuming you will have state hooks to manage dropdown options like instruments, gender etc.
  const [artist, setArtist] = useState({
    Firstname: '',
    Lastname: '',
    Sex: '',
    Title: '',
    PostTitle: '',
    PreferredStyle: '',
    Email:'',    
    Phone1: '',
    Phone2: '',
    DOB:'',        
    WebURL: '',
    Address1: '',
    Address2: '',
    PostCode: '',
    Village: '',
    County: '',
    Country: '',
    Origin: '',
    Languages: [],
    Productions: [],
    Buddies: [],
    mainInstrument: '',
    mainRating: '',
    additionalInstrument: '',
    secondRating: '',
    Vocals: '',
    VocalsRating: '',
    Note: '',
    Socials: [],
    //LastUpdatedOn: new Date().toISOString().split('T')[0],    
  });

  const originOptions = [
    { ID: 'AF', CountryName: 'Afghanistan' },
    { ID: 'AL', CountryName: 'Albania' },
    { ID: 'DZ', CountryName: 'Algeria' },
    { ID: 'AS', CountryName: 'American Samoa' },
    { ID: 'AD', CountryName: 'Andorra' },
    { ID: 'AO', CountryName: 'Angola' },
    { ID: 'AI', CountryName: 'Anguilla' },
    { ID: 'AQ', CountryName: 'Antarctica' },
    { ID: 'AG', CountryName: 'Antigua and Barbuda' },
    { ID: 'AR', CountryName: 'Argentina' },
    { ID: 'AM', CountryName: 'Armenia' },
    { ID: 'AW', CountryName: 'Aruba' },
    { ID: 'AU', CountryName: 'Australia' },
    { ID: 'AT', CountryName: 'Austria' },
    { ID: 'AZ', CountryName: 'Azerbaijan' },
    { ID: 'BS', CountryName: 'Bahamas' },
    { ID: 'BH', CountryName: 'Bahrain' },
    { ID: 'BD', CountryName: 'Bangladesh' },
    { ID: 'BB', CountryName: 'Barbados' },
    { ID: 'BY', CountryName: 'Belarus' },
    { ID: 'BE', CountryName: 'Belgium' },
    { ID: 'BZ', CountryName: 'Belize' },
    { ID: 'BJ', CountryName: 'Benin' },
    { ID: 'BM', CountryName: 'Bermuda' },
    { ID: 'BT', CountryName: 'Bhutan' },
    { ID: 'BO', CountryName: 'Bolivia' },
    { ID: 'BA', CountryName: 'Bosnia and Herzegovina' },
    { ID: 'BW', CountryName: 'Botswana' },
    { ID: 'BR', CountryName: 'Brazil' },
    { ID: 'BN', CountryName: 'Brunei' },
    { ID: 'BG', CountryName: 'Bulgaria' },
    { ID: 'BF', CountryName: 'Burkina Faso' },
    { ID: 'BI', CountryName: 'Burundi' },
    { ID: 'CV', CountryName: 'Cabo Verde' },
    { ID: 'KH', CountryName: 'Cambodia' },
    { ID: 'CM', CountryName: 'Cameroon' },
    { ID: 'CA', CountryName: 'Canada' },
    { ID: 'KY', CountryName: 'Cayman Islands' },
    { ID: 'CF', CountryName: 'Central African Republic' },
    { ID: 'TD', CountryName: 'Chad' },
    { ID: 'CL', CountryName: 'Chile' },
    { ID: 'CN', CountryName: 'China' },
    { ID: 'CO', CountryName: 'Colombia' },
    { ID: 'KM', CountryName: 'Comoros' },
    { ID: 'CG', CountryName: 'Congo - Brazzaville' },
    { ID: 'CD', CountryName: 'Congo - Kinshasa' },
    { ID: 'CR', CountryName: 'Costa Rica' },
    { ID: 'CI', CountryName: 'Côte d’Ivoire' },
    { ID: 'HR', CountryName: 'Croatia' },
    { ID: 'CU', CountryName: 'Cuba' },
    { ID: 'CY', CountryName: 'Cyprus' },
    { ID: 'CZ', CountryName: 'Czechia' },
    { ID: 'DK', CountryName: 'Denmark' },
    { ID: 'DJ', CountryName: 'Djibouti' },
    { ID: 'DM', CountryName: 'Dominica' },
    { ID: 'DO', CountryName: 'Dominican Republic' },
    { ID: 'EC', CountryName: 'Ecuador' },
    { ID: 'EG', CountryName: 'Egypt' },
    { ID: 'SV', CountryName: 'El Salvador' },
    { ID: 'GQ', CountryName: 'Equatorial Guinea' },
    { ID: 'ER', CountryName: 'Eritrea' },
    { ID: 'EE', CountryName: 'Estonia' },
    { ID: 'SZ', CountryName: 'Eswatini' },
    { ID: 'ET', CountryName: 'Ethiopia' },
    { ID: 'FJ', CountryName: 'Fiji' },
    { ID: 'FI', CountryName: 'Finland' },
    { ID: 'FR', CountryName: 'France' },
    { ID: 'GA', CountryName: 'Gabon' },
    { ID: 'GM', CountryName: 'Gambia' },
    { ID: 'GE', CountryName: 'Georgia' },
    { ID: 'DE', CountryName: 'Germany' },
    { ID: 'GH', CountryName: 'Ghana' },
    { ID: 'GR', CountryName: 'Greece' },
    { ID: 'GD', CountryName: 'Grenada' },
    { ID: 'GU', CountryName: 'Guam' },
    { ID: 'GT', CountryName: 'Guatemala' },
    { ID: 'GN', CountryName: 'Guinea' },
    { ID: 'GW', CountryName: 'Guinea-Bissau' },
    { ID: 'GY', CountryName: 'Guyana' },
    { ID: 'HT', CountryName: 'Haiti' },
    { ID: 'HN', CountryName: 'Honduras' },
    { ID: 'HU', CountryName: 'Hungary' },
    { ID: 'IS', CountryName: 'Iceland' },
    { ID: 'IN', CountryName: 'India' },
    { ID: 'ID', CountryName: 'Indonesia' },
    { ID: 'IR', CountryName: 'Iran' },
    { ID: 'IQ', CountryName: 'Iraq' },
    { ID: 'IE', CountryName: 'Ireland' },
    { ID: 'IL', CountryName: 'Israel' },
    { ID: 'IT', CountryName: 'Italy' },
    { ID: 'JM', CountryName: 'Jamaica' },
    { ID: 'JP', CountryName: 'Japan' },
    { ID: 'JO', CountryName: 'Jordan' },
    { ID: 'KZ', CountryName: 'Kazakhstan' },
    { ID: 'KE', CountryName: 'Kenya' },
    { ID: 'KI', CountryName: 'Kiribati' },
    { ID: 'KW', CountryName: 'Kuwait' },
    { ID: 'KG', CountryName: 'Kyrgyzstan' },
    { ID: 'LA', CountryName: 'Laos' },
    { ID: 'LV', CountryName: 'Latvia' },
    { ID: 'LB', CountryName: 'Lebanon' },
    { ID: 'LS', CountryName: 'Lesotho' },
    { ID: 'LR', CountryName: 'Liberia' },
    { ID: 'LY', CountryName: 'Libya' },
    { ID: 'LI', CountryName: 'Liechtenstein' },
    { ID: 'LT', CountryName: 'Lithuania' },
    { ID: 'LU', CountryName: 'Luxembourg' },
    { ID: 'MG', CountryName: 'Madagascar' },
    { ID: 'MW', CountryName: 'Malawi' },
    { ID: 'MY', CountryName: 'Malaysia' },
    { ID: 'MV', CountryName: 'Maldives' },
    { ID: 'ML', CountryName: 'Mali' },
    { ID: 'MT', CountryName: 'Malta' },
    { ID: 'MH', CountryName: 'Marshall Islands' },
    { ID: 'MR', CountryName: 'Mauritania' },
    { ID: 'MU', CountryName: 'Mauritius' },
    { ID: 'MX', CountryName: 'Mexico' },
    { ID: 'FM', CountryName: 'Micronesia' },
    { ID: 'MD', CountryName: 'Moldova' },
    { ID: 'MC', CountryName: 'Monaco' },
    { ID: 'MN', CountryName: 'Mongolia' },
    { ID: 'ME', CountryName: 'Montenegro' },
    { ID: 'MA', CountryName: 'Morocco' },
    { ID: 'MZ', CountryName: 'Mozambique' },
    { ID: 'MM', CountryName: 'Myanmar' },
    { ID: 'NA', CountryName: 'Namibia' },
    { ID: 'NR', CountryName: 'Nauru' },
    { ID: 'NP', CountryName: 'Nepal' },
    { ID: 'NL', CountryName: 'Netherlands' },
    { ID: 'NZ', CountryName: 'New Zealand' },
    { ID: 'NI', CountryName: 'Nicaragua' },
    { ID: 'NE', CountryName: 'Niger' },
    { ID: 'NG', CountryName: 'Nigeria' },
    { ID: 'KP', CountryName: 'North Korea' },
    { ID: 'MK', CountryName: 'North Macedonia' },
    { ID: 'NO', CountryName: 'Norway' },
    { ID: 'OM', CountryName: 'Oman' },
    { ID: 'PK', CountryName: 'Pakistan' },
    { ID: 'PW', CountryName: 'Palau' },
    { ID: 'PS', CountryName: 'Palestine' },
    { ID: 'PA', CountryName: 'Panama' },
    { ID: 'PG', CountryName: 'Papua New Guinea' },
    { ID: 'PY', CountryName: 'Paraguay' },
    { ID: 'PE', CountryName: 'Peru' },
    { ID: 'PH', CountryName: 'Philippines' },
    { ID: 'PL', CountryName: 'Poland' },
    { ID: 'PT', CountryName: 'Portugal' },
    { ID: 'QA', CountryName: 'Qatar' },
    { ID: 'RO', CountryName: 'Romania' },
    { ID: 'RU', CountryName: 'Russia' },
    { ID: 'RW', CountryName: 'Rwanda' },
    { ID: 'WS', CountryName: 'Samoa' },
    { ID: 'SM', CountryName: 'San Marino' },
    { ID: 'ST', CountryName: 'São Tomé and Príncipe' },
    { ID: 'SA', CountryName: 'Saudi Arabia' },
    { ID: 'SN', CountryName: 'Senegal' },
    { ID: 'RS', CountryName: 'Serbia' },
    { ID: 'SC', CountryName: 'Seychelles' },
    { ID: 'SL', CountryName: 'Sierra Leone' },
    { ID: 'SG', CountryName: 'Singapore' },
    { ID: 'SK', CountryName: 'Slovakia' },
    { ID: 'SI', CountryName: 'Slovenia' },
    { ID: 'SB', CountryName: 'Solomon Islands' },
    { ID: 'SO', CountryName: 'Somalia' },
    { ID: 'ZA', CountryName: 'South Africa' },
    { ID: 'KR', CountryName: 'South Korea' },
    { ID: 'SS', CountryName: 'South Sudan' },
    { ID: 'ES', CountryName: 'Spain' },
    { ID: 'LK', CountryName: 'Sri Lanka' },
    { ID: 'SD', CountryName: 'Sudan' },
    { ID: 'SR', CountryName: 'Suriname' },
    { ID: 'SE', CountryName: 'Sweden' },
    { ID: 'CH', CountryName: 'Switzerland' },
    { ID: 'SY', CountryName: 'Syria' },
    { ID: 'TW', CountryName: 'Taiwan' },
    { ID: 'TJ', CountryName: 'Tajikistan' },
    { ID: 'TZ', CountryName: 'Tanzania' },
    { ID: 'TH', CountryName: 'Thailand' },
    { ID: 'TL', CountryName: 'Timor-Leste' },
    { ID: 'TG', CountryName: 'Togo' },
    { ID: 'TK', CountryName: 'Tokelau' },
    { ID: 'TO', CountryName: 'Tonga' },
    { ID: 'TT', CountryName: 'Trinidad and Tobago' },
    { ID: 'TN', CountryName: 'Tunisia' },
    { ID: 'TR', CountryName: 'Turkey' },
    { ID: 'TM', CountryName: 'Turkmenistan' },
    { ID: 'TV', CountryName: 'Tuvalu' },
    { ID: 'UG', CountryName: 'Uganda' },
    { ID: 'UA', CountryName: 'Ukraine' },
    { ID: 'AE', CountryName: 'United Arab Emirates' },
    { ID: 'GB', CountryName: 'United Kingdom' },
    { ID: 'US', CountryName: 'United States' },
    { ID: 'UY', CountryName: 'Uruguay' },
    { ID: 'UZ', CountryName: 'Uzbekistan' },
    { ID: 'VU', CountryName: 'Vanuatu' },
    { ID: 'VA', CountryName: 'Vatican City' },
    { ID: 'VE', CountryName: 'Venezuela' },
    { ID: 'VN', CountryName: 'Vietnam' },
    { ID: 'YE', CountryName: 'Yemen' },
    { ID: 'ZM', CountryName: 'Zambia' },
    { ID: 'ZW', CountryName: 'Zimbabwe' }
  ];
  






  const [instrumentOptions, setInstrumentOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [productionOptions, setProductionOptions] = useState([]);
  const [buddyOptions, setBuddyOptions] = useState([]);
  const [socialOptions, setSocialOptions] = useState([]);
  //const [artists, setArtists] = useState([]);
  //const [SelectedArtist, setSelectedArtist] = useState(null);
  const [addEditStatus, setaddEditStatus] = useState(null);
  const [usedBuddyOptions, setUsedBuddyOptions] = useState([]);

  // Replace these with actual state data populated from the database
  async function getInstrumentValues() {    
    const axRequest = await axios.get('/api/instruments');
    const instrumentOptions = axRequest.data;        
    setInstrumentOptions(instrumentOptions);
  }    
  async function getGenderValues() {
    const axRequest = await axios.get('/api/gender');
    const genderOptions = axRequest.data;    
    setGenderOptions(genderOptions);
  }
  async function getLanguageValues() {
    const axRequest = await axios.get('/api/languages');
    const languageOptions = axRequest.data;    
    setLanguageOptions(languageOptions);
  }

  async function getProductionValues() {
    const axRequest = await axios.get('/api/productions');
    const productionOptions = axRequest.data;    
    setProductionOptions(productionOptions);
  }

  async function getBuddyValues() {
    const axRequest = await axios.get('/api/artists');
    const buddyOptions = axRequest.data;                    
    setBuddyOptions(buddyOptions);
  }

  // Make this function optional by adding a default parameter
  function refreshBuddyValues(artistId = null) {        
    if (artistId) {      
      const updatedBuddyOptions = buddyOptions.filter(obj => obj.ID != artistId);      
      setUsedBuddyOptions(updatedBuddyOptions);
    } else {      
      setUsedBuddyOptions(buddyOptions);
    }
  }

  async function getSocialValues() {
    const axRequest = await axios.get('/api/socials');
    const socialOptions = axRequest.data;
    setSocialOptions(socialOptions);
  }

  /*
  async function fetchArtists () {
    try {
      const { data } = await axios.get('/api/artists');
      setArtists(data);
    } catch (error) {
      console.error('Failed to fetch artists:', error);
    }
  };
  */

  useEffect(() => {    
    //fetchArtists();
    getInstrumentValues();
    getGenderValues();
    getLanguageValues();
    getProductionValues();
    getBuddyValues();
    getSocialValues();
    refreshBuddyValues();
  }, []);
            
  //const titleOptions = ['Mr.', 'Ms.', 'Dr.', 'Prof.'];
  //const languageOptions = ['English', 'German', 'French', 'Italian', 'Mandarin', 'Spanish', 'Portuguese', 'Russian', 'Japanese', 'Arabic', 'Hindi',  'Urdu', 'Turkish',  'Chinese',  'Japanese' ];
  const vocalOptions = ['Tenor', 'Soprano', 'Alto', 'Bass', 'Baritone', 'Mezzo-Soprano', 'Contralto', 'Countertenor', 'Treble', 'Falsetto'];
  // ... Add more options for each dropdown

  const handleChange = (e) => {
    setaddEditStatus(null);
    const { name, value } = e.target;    
    if (e.target.multiple) {
      const options = e.target.options;
      const value = [];
      for (let i = 0, len = options.length; i < len; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      setArtist(prevState => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setArtist(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const handleStarChange = (value, name) => {
    setaddEditStatus(null);
    setArtist(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSocialChange = (icon, e) => {
    setaddEditStatus(null);
    setArtist(prevArtist => {      
      const existingSocialIndex = prevArtist.Socials.findIndex(social => social.icon === icon);  
      const updatedSocials = [...prevArtist.Socials];
      if (existingSocialIndex >= 0) {        
        updatedSocials[existingSocialIndex] = { ...updatedSocials[existingSocialIndex], value: e.target.value };
      } else {        
        updatedSocials.push({ icon, value: e.target.value });
      }        
      return { ...prevArtist, Socials: updatedSocials };
    });
  };
            
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!artist.Firstname || artist.Firstname === '') {
      setaddEditStatus('Please specify the first name');
      return;
    } else if (!artist.Lastname || artist.Lastname === '') {
      setaddEditStatus('Please specify the last name');
      return;
    } else if (!artist.Email || artist.Email === '') {
      setaddEditStatus('Please specify an email address');
      return;
    } else if (!artist.Phone1 || artist.Phone1 === '') {
      setaddEditStatus('Please specify the phone number');
      return;
    } else if (!artist.Address1 || artist.Address1 === '') {
      setaddEditStatus('Please specify the address');
      return;
    } else if (!artist.Country || artist.Country === '') {
      setaddEditStatus('Please specify the country');
      return;
    } else if (!artist.Sex || artist.Sex === '') {
      setaddEditStatus('Please specify the Sex / Gender');
      return;
    } else if (!artist.PreferredStyle || artist.PreferredStyle === '') {
      setaddEditStatus('Please specify the preferred style');
      return;
    } else if (!artist.mainInstrument || artist.mainInstrument === '') {
      setaddEditStatus('Please specify the main instrument');
      return;
    } else if (!artist.Languages || artist.Languages.length === 0) {
      setaddEditStatus('Please specify at least one language');
      return;
    } 
    
    /*
    if (SelectedArtist) {                  
      const response = await axios.post('/api/update-artist', artist);      
      console.log(response);
      fetchArtists();
      getBuddyValues();
      setaddEditStatus('Artist updated successfully');
    } else {
      */
        const response = await axios.post('/api/add-artist', artist);
        console.log(response);        
        getBuddyValues();
        setArtist({        
          Firstname: '', Lastname: '', Sex: '', Title: '', PostTitle: '', PreferredStyle: '',
          Email:'', Phone1: '', Phone2: '', WebURL: '', Address1: '', Address2: '',
          PostCode: '', Village: '', County: '', Country: '', Origin: '', Languages: [], Productions: [],
          Buddies: [], mainInstrument: '', mainRating: '', additionalInstrument: '', secondRating: '',
          Vocals: '', VocalsRating: '', Note: '',  Socials: [], DOB:'', //LastUpdatedOn: new Date().toISOString().split('T')[0],    
        });              
        setaddEditStatus('Artist added successfully');
    //}    
    
  };

  /*
  const handleArtistSelect = async (artistId) => {
    setaddEditStatus(null);
    refreshBuddyValues(artistId); 
    if (!artistId) {
      setArtist({        
          Firstname: '', Lastname: '', Sex: '', Title: '', PostTitle: '', PreferredStyle: '',
          Email:'', Phone1: '', Phone2: '', WebURL: '', Address1: '', Address2: '',
          PostCode: '', Village: '', County: '', Country: '', Languages: [], Productions: [],
          Buddies: [], mainInstrument: '', mainRating: '', additionalInstrument: '', secondRating: '',
          Vocals: '', VocalsRating: '', Note: '',  Socials: [], DOB:'', //LastUpdatedOn: new Date().toISOString().split('T')[0],    
      });      
      setSelectedArtist(null);      
      return;
    }

    try {
      setSelectedArtist(artistId);
      const { data } = await axios.get(`/api/artist/${artistId}`);
      setArtist(data);      
    } catch (error) {
      console.error('Failed to fetch artist details:', error);
    }
  };
  */

  return (
    <form onSubmit={handleSubmit}>
    {addEditStatus && <p className='add-result'>{addEditStatus}</p>}
    <div className="artist-page-container">      
      <div className="section left-section">
        {/*
        <div className="artist-select-dropdown">
          
          <label htmlFor="artist-select">Select Existing Artist: </label>
          <select id="artist-select" onChange={(e) => handleArtistSelect(e.target.value)}>
            <option value="">Select an Artist / Add New Artist</option>
            {artists.map((artist) => (
              <option key={artist.ID} value={artist.ID}>{artist.Firstname} {artist.Lastname}</option>
            ))}
          </select>
            
        </div>      
              */}
        <label htmlFor="Firstname" className="form-label">First Name</label>
        <input        type="text"        name="Firstname"        value={artist.Firstname}        onChange={handleChange}        placeholder="First Name"      />

        <label htmlFor="Lastname" className="form-label">Last Name</label>
        <input        type="text"        name="Lastname"        value={artist.Lastname}        onChange={handleChange}        placeholder="Last Name"      />

        <label htmlFor="Email" className="form-label">Email</label>
        <input        type="email"        name="Email"        value={artist.Email}        onChange={handleChange}        placeholder="Email"      />
        
        <label htmlFor="DOB" className="form-label">Date of Birth</label>
        <input        type="date"        name="DOB"        value={artist.DOB}        onChange={handleChange}        placeholder="Date of Birth"      />
        
        <label htmlFor="Phone1" className="form-label">Tel1</label>
        <input        type="tel"        name="Phone1"        value={artist.Phone1}        onChange={handleChange}        placeholder="Phone 1"      />
        
        <label htmlFor="Phone2" className="form-label">Tel2</label>
        <input        type="tel"        name="Phone2"        value={artist.Phone2}        onChange={handleChange}        placeholder="Phone 2"      />

        <label htmlFor="Address1" className="form-label">Address 1</label>
        <input        type="text"        name="Address1"        value={artist.Address1}        onChange={handleChange}        placeholder="Address 1"      />

        <label htmlFor="Address2" className="form-label">Address 2</label>
        <input        type="text"        name="Address2"        value={artist.Address2}        onChange={handleChange}        placeholder="Address 2"      />

        <label htmlFor="PostCode" className="form-label">Post Code</label>
        <input        type="text"        name="PostCode"        value={artist.PostCode}        onChange={handleChange}        placeholder="Post Code"      />

        <label htmlFor="Village" className="form-label">Village</label>
        <input        type="text"        name="Village"        value={artist.Village}        onChange={handleChange}        placeholder="Village"      />

        <label htmlFor="County" className="form-label">County</label>
        <input        type="text"        name="County"        value={artist.County}        onChange={handleChange}        placeholder="County"      />
        

        <label htmlFor="Country" className="form-label">Country</label>
        { /* <input        type="text"        name="Country"        value={artist.Country}        onChange={handleChange}        placeholder="Country"      /> */ }
        <select name="Country"  className="form-select" value={artist.Country || ''} onChange={handleChange}>
          <option disabled value="">Country</option>
          {originOptions.map((option, index) => (
            <option key={index} value={option.Name}>{option.CountryName}</option>
          ))}
        </select>

        <label htmlFor="Origin" className="form-label">Nationality</label>
        <select name="Origin"  className="form-select" value={artist.Origin || ''} onChange={handleChange}>
          <option disabled value="">Nationality</option>
          {originOptions.map((option, index) => (
            <option key={index} value={option.CountryName}>{option.CountryName}</option>
          ))}
        </select>


        <label htmlFor="WebURL" className="form-label">Website URL</label>
        <input        type="text"        name="WebURL"        value={artist.WebURL}        onChange={handleChange}        placeholder="Web URL"      />

        <label htmlFor="Sex" className="form-label">Gender</label>
        <select name="Sex"  className="form-select" value={artist.Sex || ''} onChange={handleChange}>
          <option disabled value="">Select Gender</option>
          {genderOptions.map((option, index) => (
            <option key={index} value={option.ID}>{option.Sex}</option>
          ))}
        </select>

        <label htmlFor="Title" className="form-label">Title</label>
        <input type="text" name="Title" value={artist.Title} onChange={handleChange} placeholder="Title" />
      </div>

      <div className="section right-section">        
                                    
        <label htmlFor="PostTitle" className="form-label">Post Title</label>
        <input type="text" name="PostTitle" value={artist.PostTitle} onChange={handleChange} placeholder="Post Title" />
        
        <label htmlFor="PreferredStyle" className="form-label">Preferred Style</label>
        <select name="PreferredStyle"  className="form-select" value={artist.PreferredStyle || ''} onChange={handleChange}>
          <option disabled value="">Select Preferred Style</option>
          <option value="Formal">Formal</option>
          <option value="Informal">Informal</option>
        </select>

        <label htmlFor="mainInstrument" className="form-label">Primary Instrument</label>
        <select name="mainInstrument"  className="form-select" value={artist.mainInstrument || ''} onChange={handleChange}>
          <option disabled value="">Select Main Instrument</option>
          {instrumentOptions.map((option, index) => (
            <option key={index} value={option.ID}>{option.InstrumentName}</option>
          ))}
        </select>
        
        <label htmlFor="mainRating" className="form-label">Main Instrument Rating</label>
        <StarRating
          rating={artist.mainRating}
          onRatingChange={(value) => handleStarChange(value, 'mainRating')}
        />
        
        <label htmlFor="additionalInstrument" className="form-label">Secondary Instrument</label>
        <select name="additionalInstrument" className="form-select" value={artist.additionalInstrument || ''} onChange={handleChange}>
          <option disabled value="">Select Additional Instrument</option>
          {instrumentOptions.map((option, index) => (
            <option key={index} value={option.ID}>{option.InstrumentName}</option>
          ))}
        </select>
        <label htmlFor="secondRating" className="form-label">Additional Instrument Rating</label>
        <StarRating
          rating={artist.secondRating}
          onRatingChange={(value) => handleStarChange(value, 'secondRating')}
        />

        <label htmlFor="Vocals" className="form-label">Vocals</label>
        <select name="Vocals"  className="form-select" value={artist.Vocals || ''} onChange={handleChange}>
          <option disabled value="">Select Vocals</option>
          {vocalOptions.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
        
        <label htmlFor="VocalsRating" className="form-label">Vocals Rating</label>
        <StarRating
          rating={artist.VocalsRating}
          onRatingChange={(value) => handleStarChange(value, 'VocalsRating')}
        />

        {socialOptions.map((social, index) => (          
            <div key={`sociald${index}`} className="socials">
              <label key={`social${index}`} htmlFor={social.icon} className="form-label">{social.Icon} Handle:</label>
              <input key={`socialv${index}`} type="text" name={social.Icon} value={artist.Socials.find(s => s.icon === social.ID)?.value || ''} onChange={(e) => handleSocialChange(social.ID, e)} placeholder={`${social.Icon} handle`} />          
            </div>
        ))}

            


        <label htmlFor="Productions" className="form-label">Productions</label>
        <select name="Productions"  className="form-select" value={artist.Productions} onChange={handleChange} multiple>
          {productionOptions.map((option, index) => (
            <option key={index} value={option.ID}>{option.Location} - {option.Name} : {option.Year} - {option.Month}</option>
          ))}
        </select>

        <label htmlFor="Buddies" className="form-label">Buddies</label>
        <select name="Buddies"  className="form-select" value={artist.Buddies} onChange={handleChange} multiple>
          {usedBuddyOptions.map((option, index) => (
            <option key={index} value={option.ID}>{option.Firstname} {option.Lastname}</option>
          ))}
        </select>

        <label htmlFor="Languages" className="form-label">Languages</label>
        <select name="Languages"  className="form-select" value={artist.Languages} onChange={handleChange} multiple>
          {languageOptions.map((option, index) => (
            <option key={index} value={option.ID}>{option.Language}</option>
          ))}
        </select>

        <label htmlFor="Note" className="form-label">Note</label>
        <textarea name="Note" value={artist.Note} onChange={handleChange} placeholder="Note" />
        
      </div>        
    </div>
    <button type="submit" className="submit-button">Add Artist</button>      
    </form>
  );
};

export default AddArtistPage;